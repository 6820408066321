import "core-js/modules/es6.array.find-index.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
export default {
  mixins: [Base],
  data: function data() {
    return {
      swiperOption: {
        loop: false,
        freeMode: !this.isDesktop,
        slidesPerGroup: 1,
        preloadImages: false,
        lazy: true,
        slidesPerView: 'auto',
        slidesPerColumn: 1,
        slidesPerGroupSkip: 2,
        watchSlidesVisibility: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    };
  },
  computed: {
    profile: function profile() {
      return this.$store.getters['player/profile'];
    },
    currentLevel: function currentLevel() {
      return this.$store.getters['player/currentLevel'];
    },
    levels: function levels() {
      return this.$store.getters['player/allLevels'];
    },
    levelIndex: function levelIndex() {
      var _this = this;

      return this.levels.findIndex(function (l) {
        return l.code === _this.currentLevel.code;
      });
    },
    balance: function balance() {
      return this.$store.getters['balance/playerBalance'];
    },
    rate: function rate() {
      if (this.profile.Registration && this.profile.Registration.complete) {
        var rate = +this.profile.Registration.complete.rate;
        return rate;
      }

      return 0;
    }
  },
  mounted: function mounted() {
    if (this.levelIndex > 0) this.myswiper.slideTo(this.levelIndex);
  }
};